import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class SpecialOffer {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.swiperEl = this.root.querySelector('[data-special-offer-swiper]');
        this.prevEl = this.root.querySelector(
            '[data-special-offer-swiper-prev]'
        );
        this.nextEl = this.root.querySelector(
            '[data-special-offer-swiper-next]'
        );

        this.swiper = null;

        return true;
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        this.swiper = new Swiper(this.swiperEl, {
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            navigation: {
                prevEl: this.prevEl,
                nextEl: this.nextEl,
            },
        });
    }
}

function initSpecialOfferSections() {
    const sections = document.querySelectorAll('[data-special-offer]');
    if (!sections) return;

    sections.forEach(item => new SpecialOffer(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initSpecialOfferSections();
} else {
    document.addEventListener('DOMContentLoaded', initSpecialOfferSections);
}
